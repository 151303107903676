import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { Checkbox } from '@paljs/ui/Checkbox';
import '../../styles/common.css';
import ReactPaginate from 'react-paginate';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  baseUri,
  showLoader,
  hideLoader,
  authCheck,
  PackingSlipCategory as category,
  checkNumber,
  appendErrorMsg,
  randomKey,
  buildQuery,
  ADMIN,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';
import PdfComponent from '../../components/Pdfs/packing-slip-transaction';
import { pdf } from '@react-pdf/renderer';
import moment from 'moment';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;
const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const initPiece = {
  color: '',
  meters: '',
};

const initialFormInput = {
  id: '',
  baleNum: '',
  roleNum: '',
  year: new Date().getFullYear(),
  date: dateFormat(new Date()),
  cotton: false,
  mmf: false,
  jobCard: '',
  customerName: '',
  customerSortName: '',
  blendName: '',
  category: '',
  piecesPacked: '',
  totalMeters: '',
  pieces: [],
  available: 0,
  baleTotal: '',
  ntWeight: '',
  grWeight: '',
  billCode: '',
  remarks: '',
};

const PackingSlipEntry = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [formInput, setFormInput] = useState(() => initialFormInput);
  const [pickId, setPickId] = useState('');

  const [defaultJobCard, setDefaultJobCard] = useState([]);
  const [jobCardOptions, setJobCardOptions] = useState([]);

  const [defaultCategory, setDefaultCategory] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState(() => category());

  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [formOK, setFormOK] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  const [packedSoFar, setPackedSoFar] = useState('');
  const [availableForPacking, setAvailableForPacking] = useState('');
  const [tempBaleMeter, setTempBaleMeter] = useState('');
  useEffect(() => {
    authCheck(authState.authData);
    baleNumSetter();
    function eventHandler(ev) {
      if (ev.key === 'Enter' && ev.target.nodeName === 'INPUT') {
        let focusableElementsString =
          'a[href], area[href], input:not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
        let ele = document.querySelector('.packing-slip-entry');
        let ol = ele.querySelectorAll(focusableElementsString);
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = i < ol.length - 1 ? ol[i + 1] : o[0];
            o.focus();
            break;
          }
        }
        ev.preventDefault();
      }
    }
    document.addEventListener('keydown', eventHandler);
    return () => {
      document?.removeEventListener('keydown', eventHandler);
    };
  }, []);

  useEffect(() => {
    getApi();
  }, [page]);

  useEffect(() => {
    if (formInput.jobCard === '') return;
    axios
      .get(`${baseUri()}packing-slips/baleTotal?jobCard=${formInput.jobCard}`)
      .then((response) => {
        let res = response.data.data;
        if (res.length !== 0) {
          let baleTotal = +res[0].bale_total;
          setPackedSoFar(baleTotal.toFixed(2));
          setAvailableForPacking((formInput.totalMeters - baleTotal).toFixed(2));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formInput.jobCard]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const baleNumSetter = () => {
    axios
      .get(`${baseUri()}packing-slips/newBaleNum?date=${dateFormat(new Date())}`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let curr = { ...initialFormInput };
        curr.baleNum = response.data.data.baleNum;
        setFormInput(curr);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    showPi(id);
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const AddItem = () => {
    let newItem = [...formInput.pieces];
    for (let i = 0; i < 5; i++) {
      newItem.push({ ...initPiece });
    }
    let data = { ...formInput, pieces: newItem, piecesPacked: newItem.filter((t) => t.color !== '').length };
    if (formInput.pieces.filter((t) => t.color !== '').length === 0) {
      data.baleTotal = 0;
    }
    setFormInput(data);
  };

  const removeItems = (index) => {
    formInput.baleTotal = Number(formInput.baleTotal) - Number(formInput.pieces[index].meters);
    formInput.preUsedMeters = Number(formInput.preUsedMeters) - Number(formInput.pieces[index].meters);
    formInput.available = Number(formInput.available) + Number(formInput.pieces[index].meters);
    if (formInput.pieces[index].isOld) {
      formInput.prePiecesSum = Number(formInput.prePiecesSum) - Number(formInput.pieces[index].meters);
    }

    let items = formInput.pieces.filter((value, key) => {
      return key !== index;
    });
    setFormInput({
      ...formInput,
      pieces: items,
      piecesPacked: items.length,
      prePiecesSum: formInput.prePiecesSum,
      available: formInput.available,
      baleTotal: formInput.baleTotal,
      preUsedMeters: formInput.preUsedMeters,
    });
  };

  const handleChange = (e) => {
    resetRequired(e);
    let data;
    const { name, value } = e.target;
    if (name == 'grWeight' || name == 'ntWeight') {
      data = checkNumber(e.target, '3.2');
    } else if (name === 'baleNum' || name === 'roleNum') {
      data = +checkNumber(e.target, '5');
    } else {
      data = value;
    }
    setFormInput({ ...formInput, [name]: data });
  };

  const itemInputChange = (e, index, isMeter = false) => {
    resetRequired(e);
    const { name, value } = e.target;
    formInput.pieces[index][name] = isMeter ? checkNumber(e.target) : value;
    setFormInput({
      ...formInput,
      pieces: formInput.pieces,
      piecesPacked: formInput.pieces.filter((t) => t.color !== '').length,
    });
    if (isMeter) {
      let tot = 0;
      formInput.pieces.forEach((piece) => {
        tot = tot + Number(piece.meters);
      });
      let currentSum = tot - Number(formInput.prePiecesSum);
      let available = formInput.totalMeters - (Number(formInput.preUsedMeters) + Number(currentSum));
      setFormInput((pre) => {
        return {
          ...pre,
          available: available,
          baleTotal: tot.toFixed(2),
          piecesPacked: formInput.pieces.filter((t) => t.color !== '').length,
        };
      });
      setFormOK(false);
    }
  };

  const checkBoxChange = (e, name) => {
    let currFormInp = { ...formInput };
    if (name === 'cotton' && e) {
      currFormInp['mmf'] = false;
    } else if (name === 'mmf' && e) {
      currFormInp['cotton'] = false;
    }
    setFormInput({ ...currFormInp, [name]: e });
    let ele = document.querySelector('#type');
    resetRequired(false, ele);
    ele.style.border = '0';
  };

  const categoryChange = (event) => {
    setFormInput({ ...formInput, category: event.label });
    setDefaultCategory({ label: event.label, value: event.value });
  };

  const jobcardChange = (event) => {
    setFormInput({
      ...formInput,
      jobCard: event.value,
      customerName: event.customer,
      customerSortName: `${event.sort} - ${event.shade}`,
      blendName: event.blend,
      totalMeters: event.totalMeters,
      preUsedMeters: event.preUsedMeters,
      available: event.pendingMeters,
      prePiecesSum: 0,
    });
    setDefaultJobCard({ label: event.label, value: event.value });
    pullDownReset('jobCard');
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setPickId('');
    setDefaultJobCard([]);
    setDefaultCategory([]);
    resetRequired();
    setPackedSoFar('');
    setAvailableForPacking('');
    setTempBaleMeter('');
    setFormInput({ ...initialFormInput });
    setFormOK(false);
  };

  const showPi = (id) => {
    showLoader();
    axios
      .get(
        baseUri() +
          `packing-slips/${id}?populate[0]=jobCard&populate[1]=jobCard.processInstruction&populate[2]=jobCard.processInstruction.customer&populate[3]=jobCard.processInstruction.customerSort&populate[4]=jobCard.processOrder&populate[5]=jobCard.processOrder.customer&populate[6]=jobCard.processOrder.customersSort&populate[7]=jobCard.processedGreys&populate[8]=jobCard.processedGreys.greyReceiptAdvice&populate[9]=jobCard.processInstruction.customerSort.sort&populate[10]=jobCard.processOrder.customersSort.sort&populate[11]=pieces`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setShowData(response.data.data.attributes.pieces);
        setIsModalOpen(true);
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'packing-slips?populate[0]=jobCard&populate[1]=jobCard.processInstruction&populate[2]=jobCard.processInstruction.customer&populate[3]=jobCard.processInstruction.customerSort&populate[4]=jobCard.processOrder&populate[5]=jobCard.processOrder.customer&populate[6]=jobCard.processOrder.customersSort&populate[7]=jobCard.processedGreys&populate[8]=jobCard.processedGreys.greyReceiptAdvice&populate[9]=jobCard.processInstruction.customerSort.sort&populate[10]=jobCard.processOrder.customersSort.sort&populate[11]=pieces&sort[0]=id:desc&pagination[page]=' +
              page +
              '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
        if (page == 1 && totalPage == 0) {
          getJobCards();
        }
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getJobCards = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          'job-cards?sort[0]=id:desc&populate[packingSlips][populate][pieces][fields][0]=meters&populate[processedGreys][fields][0]=metersProcessed&populate[processInstruction][populate][customer][fields][0]=name&populate[processInstruction][populate][customerSort][fields][0]=sortName&populate[processInstruction][populate][customerSort][populate][sort][fields][0]=blend1&populate[processInstruction][populate][customerSort][populate][sort][fields][0]=blendPercent1&populate[processInstruction][populate][customerSort][populate][sort][fields][0]=blend2&populate[processInstruction][populate][customerSort][populate][sort][fields][0]=blendPercent2&populate[processInstruction][populate][customerSort][populate][sort][fields][0]=blend3&populate[processInstruction][populate][customerSort][populate][sort][fields][0]=blendPercent3&populate[processOrder][populate][customersSort][populate][sort][fields][0]=blend1&populate[processOrder][populate][customersSort][populate][sort][fields][0]=blendPercent1&populate[processOrder][populate][customersSort][populate][sort][fields][0]=blend2&populate[processOrder][populate][customersSort][populate][sort][fields][0]=blendPercent2&populate[processOrder][populate][customersSort][populate][sort][fields][0]=blend3&populate[processOrder][populate][customersSort][populate][sort][fields][0]=blendPercent3&populate[processOrder][populate][customer][fields][0]=name&populate[processOrder][populate][customersSort][fields][0]=sortName&filters[status][$eq]=open',
        //  'job-cards?populate[0]=processInstruction&populate[1]=processInstruction.customer&populate[2]=processInstruction.customerSort&populate[3]=processOrder&populate[4]=processOrder.customer&populate[5]=processOrder.customersSort&populate[6]=processedGreys&populate[7]=processedGreys.greyReceiptAdvice&populate[8]=currentGroup&populate[9]=currentOperation&populate[10]=nextGroup&populate[11]=nextOperation&populate[12]=processInstruction.customerSort.sort&populate[13]=processOrder.customersSort.sort&sort[0]=id:desc',
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )

      .then((response) => {
        let jobcardoptions = [];
        response.data.data.forEach(function (item, index) {
          let totalMeters = 0;
          let usedPieces = 0;
          let sortName = '';
          let customer = '';
          let blend = '';
          let shade = '';
          if (item.attributes.processedGreys.data) {
            item.attributes.processedGreys.data.forEach(function (grey, key) {
              totalMeters = totalMeters + parseInt(grey.attributes.metersProcessed);
            });
          }
          if (item.attributes.packingSlips.data) {
            item.attributes.packingSlips.data.forEach(function (slip, key) {
              slip.attributes.pieces.forEach(function (item, key2) {
                usedPieces = usedPieces + parseInt(item.meters);
              });
            });
          }
          if (item.attributes.processInstruction.data) {
            let sortOps =
              item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sort.data.attributes;
            blend = `${sortOps.blend1 ? sortOps.blend1 + ' - ' + sortOps.blendPercent1 : ''} ${
              sortOps.blend2 ? ', ' + sortOps.blend2 + ' - ' + sortOps.blendPercent2 : ''
            } ${sortOps.blend3 ? ', ' + sortOps.blend3 + ' - ' + sortOps.blendPercent3 : ''}`;
            sortName = item.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName;
            customer = item.attributes.processInstruction.data.attributes.customer.data.attributes.name;
          }
          if (item.attributes.processOrder.data) {
            let sortOps =
              item.attributes.processOrder.data.attributes.customersSort.data.attributes.sort.data.attributes;
            blend = `${sortOps.blend1 ? sortOps.blend1 + ' - ' + sortOps.blendPercent1 : ''} ${
              sortOps.blend2 ? ', ' + sortOps.blend2 + ' - ' + sortOps.blendPercent2 : ''
            } ${sortOps.blend3 ? ', ' + sortOps.blend3 + ' - ' + sortOps.blendPercent3 : ''}`;
            sortName = item.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName;
            customer = item.attributes.processOrder.data.attributes.customer.data.attributes.name;
            shade = item.attributes.processOrder.data.attributes.shade;
          }
          jobcardoptions.push({
            label: `${item.attributes.idLotNum} - ${item.id} - ${customer}`,
            value: item.id,
            totalMeters: totalMeters,
            pendingMeters: totalMeters - usedPieces,
            preUsedMeters: usedPieces,
            sort: sortName,
            customer: customer,
            blend: blend,
            shade: shade,
          });
        });
        setJobCardOptions(jobcardoptions);
        hideLoader();
      })
      .catch((error) => {
        console.error(error);
        //return  error;
      });
  };

  const getLocaleNumber = (num) => {
    if (num === 0) {
      return '-';
    }
    let currNum = +num;
    return currNum.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };

  const postApi = () => {
    const postData = {
      data: {
        ...formInput,
      },
    };
    let validate = requireCheck(postData);
    if (!formInput.mmf && !formInput.cotton) {
      let ele = document.querySelector('#type');
      appendErrorMsg(ele, ele?.parentElement, 'Please select atleast one value');
      return false;
    }
    if (+formInput.baleTotal !== +tempBaleMeter) {
      let ele = document.querySelector('#baleMeterCheck');
      appendErrorMsg(ele, ele?.parentElement, 'Bale Total Meters is different');
      return false;
    }
    if (+formInput.baleTotal <= 0) {
      let ele = document.querySelector('.baleTotal');
      appendErrorMsg(ele, ele?.parentElement, 'Value must be greater than zero');
      return false;
    }
    if (+formInput.piecesPacked <= 0) {
      let ele = document.querySelector('.piecesPacked');
      appendErrorMsg(ele, ele?.parentElement, 'Value must be greater than zero');
      return false;
    }
    if (!validate) {
      return false;
    }
    postData.data.pieces = postData.data.pieces.filter((t) => t.color !== '' || t.meters !== '');

    for (const key in postData['data']) {
      if (postData['data'][key] === '') {
        delete postData['data'][key];
      }
    }
    if (+formInput.baleTotal > +availableForPacking) {
      toast('Packed Quantity is more than the available Quantity');
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'packing-slips' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
          getJobCards();
          baleNumSetter();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .get(
          `${baseUri()}packing-slips/checkBaleNum?year=${formInput.year}&baleNum=${formInput.baleNum}&date=${dateFormat(
            formInput.date
          )}`,
        )
        .then((res) => {
          let validity = res.data.data.isValid;
          if (!validity) {
            let baleEle = document.querySelector('.baleNum');
            appendErrorMsg(baleEle, baleEle?.parentElement, 'Bale num is already in use');
            hideLoader();
          } else {
            axios
              .post(
                baseUri() + 'packing-slips',
                { ...postData },
                {
                  headers: {
                    authorization: 'Bearer ' + authState.authData,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                },
              )
              .then((response) => {
                clearFormData();
                getApi();
                getJobCards();
                baleNumSetter();
              })
              .catch((error) => {
                //return  error;
              });
          }
        });
    }
  };

  const editRecord = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(
        baseUri() +
          `packing-slips/${id}?populate[0]=jobCard&populate[1]=jobCard.processInstruction&populate[2]=jobCard.processInstruction.customer&populate[3]=jobCard.processInstruction.customerSort&populate[4]=jobCard.processOrder&populate[5]=jobCard.processOrder.customer&populate[6]=jobCard.processOrder.customersSort&populate[7]=jobCard.processedGreys&populate[8]=jobCard.processedGreys.greyReceiptAdvice&populate[9]=jobCard.processInstruction.customerSort.sort&populate[10]=jobCard.processOrder.customersSort.sort&populate[11]=pieces&populate[12]=jobCard.packingSlips.pieces`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setPickId(response.data.data.id);
        let row = response.data.data.attributes;
        let cotton = row.cotton ? row.cotton : '';
        let mmf = row.mmf ? row.mmf : '';
        let category = row.category ? row.category : '';
        let piecesPacked = row.piecesPacked ? row.piecesPacked : '';
        let date = dateFormat(row.date);
        let jobCard = row.jobCard.data ? row.jobCard.data.id : '';
        let customerSortName = jobCardInfo(row.jobCard.data, 1);
        let customerName = jobCardInfo(row.jobCard.data, 2);
        let blendName = jobCardInfo(row.jobCard.data, 3);
        let totalMeters = jobCardInfo(row.jobCard.data, 4);
        let usedMeters = jobCardInfo(row.jobCard.data, 5);
        let shade = jobCardInfo(row.jobCard.data, 6);
        let id = response.data.data.id;
        let ntWeight = row.ntWeight;
        let grWeight = row.grWeight;
        let baleNum = row.baleNum;
        let roleNum = row.roleNum;
        let year = row.year ? row.year : new Date().getFullYear();
        setSave(
          authState.authEmail === 'ublmtp@gmail.com' || (pageAccess.edit === 1 && date === dateFormat(new Date())),
        );
        setFormInput({
          ...initialFormInput,
          id: id,
          cotton: cotton,
          mmf: mmf,
          date: date,
          jobCard: jobCard,
          category: category,
          piecesPacked: piecesPacked,
          customerName: customerName,
          customerSortName: `${customerSortName}-${shade}`,
          blendName: blendName,
          totalMeters: totalMeters,
          preUsedMeters: usedMeters,
          ntWeight: ntWeight,
          grWeight: grWeight,
          baleNum: baleNum,
          roleNum: roleNum,
          year: year,
          billCode: row.billCode,
          remarks: row.remarks,
        });

        let defCat = categoryOptions.filter((item, index) => {
          return item.label == category;
        });
        setDefaultCategory(defCat);

        if (row.jobCard.data) {
          setDefaultJobCard({
            label: row.jobCard.data.attributes.idLotNum + ' - ' + row.jobCard.data.id + ' - ' + customerName,
            value: row.jobCard.data.id,
          });
        }
        let pieces = [];
        let totPieces = 0;
        let availPieces = 0;
        row.pieces.forEach((item) => {
          pieces.push({
            color: item.color,
            meters: item.meters,
            isOld: 1,
          });
          totPieces += parseFloat(item.meters);
        });

        availPieces = parseFloat(totalMeters) - parseFloat(usedMeters);
        totPieces = row.baleTotal ? row.baleTotal : totPieces;
        setFormInput((prev) => {
          return { ...prev, pieces: pieces, baleTotal: totPieces, available: availPieces, prePiecesSum: totPieces };
        });
        setTempBaleMeter(totPieces);
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        return error;
        hideLoader();
      });
  };

  const deleteRecord = (id) => {
    const removeGroup = window.confirm('Would you like to delete this record?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + '/packing-slips/' + id, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const jobCardInfo = (row, mode) => {
    let blend = '',
      sortName = '',
      customer = '',
      shade = '',
      shortName = '',
      totalMeters = 0,
      availMeters = 0;
    if (row) {
      if (row.attributes.processedGreys.data) {
        row.attributes.processedGreys.data.forEach(function (grey, key) {
          totalMeters = totalMeters + parseInt(grey.attributes.metersProcessed);
        });
      }

      if (row.attributes.processInstruction.data) {
        let sortOps =
          row.attributes.processInstruction.data.attributes.customerSort.data.attributes.sort.data.attributes;
        blend = `${sortOps.blend1 ? sortOps.blend1 + ' - ' + sortOps.blendPercent1 : ''} ${
          sortOps.blend2 ? ', ' + sortOps.blend2 + ' - ' + sortOps.blendPercent2 : ''
        } ${sortOps.blend3 ? ', ' + sortOps.blend3 + ' - ' + sortOps.blendPercent3 : ''}`;
        sortName = row.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName;
        customer = row.attributes.processInstruction.data.attributes.customer.data.attributes.name;
        shortName = row.attributes.processInstruction.data.attributes.customer.data.attributes.shortName;
      }

      if (row.attributes.processOrder.data) {
        let sortOps = row.attributes.processOrder.data.attributes.customersSort.data.attributes.sort.data.attributes;
        blend = `${sortOps.blend1 ? sortOps.blend1 + ' - ' + sortOps.blendPercent1 : ''} ${
          sortOps.blend2 ? ', ' + sortOps.blend2 + ' - ' + sortOps.blendPercent2 : ''
        } ${sortOps.blend3 ? ', ' + sortOps.blend3 + ' - ' + sortOps.blendPercent3 : ''}`;
        sortName = row.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName;
        customer = row.attributes.processOrder.data.attributes.customer.data.attributes.name;
        shade = row.attributes.processOrder.data.attributes.shade;
        shortName = row.attributes.processInstruction.data.attributes.customer.data.attributes.shortName;
      }
    }

    if (mode == 5) {
      if (row.attributes.packingSlips.data) {
        row.attributes.packingSlips.data.forEach(function (slip, key) {
          slip.attributes.pieces.forEach(function (item, key2) {
            availMeters = parseInt(availMeters) + parseInt(item.meters);
          });
        });
      }
    }

    switch (mode) {
      case 1:
        return sortName;
      case 2:
        return customer;
      case 3:
        return blend;
      case 4:
        return totalMeters;
      case 5:
        return availMeters;
      case 6:
        return shade;
      case 7:
        return shortName;
      default:
        return '';
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            id={'editData' + row.id}
            onClick={() => {
              editRecord(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {row.attributes.pieces.length ? (
            <ActionBtn
              data-tag="allowRowEvents"
              className="btn-rad ml-10"
              onClick={() => {
                handleModalOpen(row.id);
              }}
            >
              {' '}
              <EvaIcon name="eye" />{' '}
            </ActionBtn>
          ) : (
            <></>
          )}
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteRecord(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtnprint-color"
            onClick={() => {
              handlePrint(row.id);
            }}
          >
            {' '}
            <EvaIcon name="printer-outline" />{' '}
          </ActionBtn>
        </div>
      ),
    },
    {
      name: 'Bale No',
      selector: (row) => `${row.attributes.baleNum ? row.attributes.baleNum : ''}`,
      sortable: true,
      maxWidth: '3%',
    },
    {
      name: 'Date',
      selector: (row) => `${dateFormat(row.attributes.date)}`,
      sortable: true,
      maxWidth: '3%',
    },
    {
      name: 'JobCard',
      selector: (row) => `${row.attributes.jobCard.data ? row.attributes.jobCard.data.id : ''}`,
      sortable: true,
      maxWidth: '3%',
    },
    {
      name: 'ID Lot Num',
      selector: (row) =>
        `${row.attributes.jobCard?.data?.attributes?.idLotNum ? row.attributes.jobCard.data.attributes.idLotNum : ''}`,
      sortable: true,
      maxWidth: '3%',
    },
    {
      name: 'Bale Total',
      selector: (row) => `${row.attributes.baleTotal ? row.attributes.baleTotal : ''}`,
      sortable: true,
      maxWidth: '3%',
    },
    {
      name: 'Type',
      selector: (row) =>
        `${row.attributes.cotton ? 'Cotton' + (row.attributes.cotton && row.attributes.mmf ? ' | ' : '') : ''} ${
          row.attributes.mmf ? 'MMF' : ''
        }`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Sort',
      selector: (row) => `${jobCardInfo(row.attributes.jobCard.data, 1)}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Customer',
      selector: (row) => `${jobCardInfo(row.attributes.jobCard.data, 2)}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Blend',
      selector: (row) => `${jobCardInfo(row.attributes.jobCard.data, 3)}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Category',
      selector: (row) => `${row.attributes.category ? row.attributes.category : ''}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Piece Packed',
      selector: (row) => `${row.attributes.piecesPacked ? row.attributes.piecesPacked : ''}`,
      sortable: true,
      maxWidth: '5%',
    },
  ];

  const handlePrint = (id) => {
    axios
      .get(
        baseUri() +
          `packing-slips/${id}?populate[0]=jobCard&populate[1]=jobCard.processInstruction&populate[2]=jobCard.processInstruction.customer&populate[3]=jobCard.processInstruction.customerSort&populate[4]=jobCard.processOrder&populate[5]=jobCard.processOrder.customer&populate[6]=jobCard.processOrder.customersSort&populate[7]=jobCard.processedGreys&populate[8]=jobCard.processedGreys.greyReceiptAdvice&populate[9]=jobCard.processInstruction.customerSort.sort&populate[10]=jobCard.processOrder.customersSort.sort&populate[11]=pieces&populate[12]=jobCard.packingSlips.pieces`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(async (response) => {
        let row = response.data.data.attributes;
        let date = row.date;
        let customerSortName = jobCardInfo(row.jobCard.data, 1);
        let millName = jobCardInfo(row.jobCard.data, 7);
        let totalMeters = jobCardInfo(row.jobCard.data, 4);
        let shade = jobCardInfo(row.jobCard.data, 6);
        let ntWeight = getLocaleNumber(+row.ntWeight);
        let grWeight = getLocaleNumber(+row.grWeight);
        let baleNum = row.baleNum;
        let roleNum = row.roleNum;
        let pdfData = {
          millName: millName,
          SORT: `${customerSortName}`,
          SHADE: shade ? shade : '-',
          ROLL_NO: roleNum ? roleNum : '-',
          bNO: baleNum ? baleNum : '-',
          lMtrs: getLocaleNumber(+row.baleTotal),
          netWT: ntWeight,
          grossWt: grWeight,
          date: moment(date).format('DD.MM.YYYY'),
        };
        let blobData = await pdf(<PdfComponent {...pdfData} />).toBlob();
        let objUrl = await URL.createObjectURL(blobData);
        const aEle = document.createElement('a');
        aEle.target = '_blank';
        aEle.href = objUrl;
        aEle.click();
        URL.revokeObjectURL(objUrl);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
      });
  };

  const filterColumns = ['id', 'category', 'piecesPacked', 'date', 'jobCard.id', 'baleNum', 'jobCard.idLotNum'];

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>Details</h5>
          <div className="div-part1">
            <table className="full-width">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Color</th>
                  <th>Meter</th>
                </tr>
              </thead>
              <tbody>
                {showData &&
                  showData.map((row, i) => (
                    <tr key={randomKey()}>
                      <td>{row.id}</td>
                      <td>{row.color}</td>
                      <td>{row.meters}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <>
      {popup}
      <SEO title="Packing Slip Entry" />
      <Toaster />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Packing Slip Entry </header>
            <CardBody className="cardbody packing-slip-entry">
              <Row>
                <Col breakPoint={{ xs: 12, md: 2.5 }}>
                  <label>Bale No </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      readOnly={pickId ? true : false}
                      placeholder="Bale No"
                      name="baleNum"
                      value={formInput.baleNum}
                      onChange={(e) => handleChange(e)}
                      className="baleNum require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 2.5 }}>
                  <label>Role No</label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      placeholder="Role No"
                      name="roleNum"
                      value={formInput.roleNum}
                      onChange={(e) => handleChange(e)}
                      className="roleNum"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 2.5 }}>
                  <label>Date </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="date"
                      id="date"
                      placeholder="Date"
                      onChange={(e) => handleChange(e)}
                      value={formInput.date}
                      className="date require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 1.5 }}>
                  <div id="type" style={{ padding: '2px' }}>
                    <label>Type </label>
                    <Checkbox checked={formInput.cotton} onChange={(e) => checkBoxChange(e, 'cotton')} status="Success">
                      Cotton
                    </Checkbox>
                    &nbsp;&nbsp;
                    <Checkbox checked={formInput.mmf} onChange={(e) => checkBoxChange(e, 'mmf')} status="Success">
                      MMF
                    </Checkbox>
                  </div>
                </Col>
                <Col breakPoint={{ xs: 12, md: 2 }}>
                  <div style={{ padding: '2px' }}>
                    <label>Bale Meters Check</label>
                    <Input fullWidth size="Small" id="baleMeterCheck">
                      <input
                        type="number"
                        name="baleMeterCheck"
                        id="baleMeterCheck"
                        placeholder="Bale Meter (temp)"
                        onChange={(e) => {
                          let ele = document.querySelector('#baleMeterCheck');
                          resetRequired(false, ele);
                          ele.style.border = '0';
                          setTempBaleMeter(e.target.value);
                        }}
                        value={tempBaleMeter}
                        className="baleMeter check"
                      />
                    </Input>
                  </div>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Lot Number </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => jobcardChange(e)}
                    options={jobCardOptions}
                    id="jobCard"
                    name="jobCard"
                    placeholder="Select JobCard"
                    value={defaultJobCard}
                    className="pull-down jobcard require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Sort</label>
                  <Input fullWidth size="Small">
                    <input type="text" value={formInput.customerSortName} className="customerSortName" readOnly />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer</label>
                  <Input fullWidth size="Small">
                    <input type="text" value={formInput.customerName} className="customerName" readOnly />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Blend </label>
                  <Input fullWidth size="Small">
                    <input type="text" value={formInput.blendName} className="blendName" readOnly />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Total Meters </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="totalMeters"
                      placeholder="totalMeters"
                      value={formInput.totalMeters}
                      className="totalMeters"
                      readOnly
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Category </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => categoryChange(e)}
                    options={categoryOptions}
                    id="category"
                    name="category"
                    placeholder="Select Category"
                    value={defaultCategory}
                    className="pull-down require category"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Nt Weight </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="ntWeight"
                      onChange={(e) => handleChange(e)}
                      value={formInput.ntWeight}
                      className="ntWeight require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Gr Weight </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="grWeight"
                      onChange={(e) => handleChange(e)}
                      value={formInput.grWeight}
                      className="grWeight require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Packed So Far</label>
                  <Input fullWidth size="Small">
                    <input readOnly type="text" value={packedSoFar} />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Available for packing</label>
                  <Input fullWidth size="Small">
                    <input readOnly type="text" value={availableForPacking} />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Piece Packed </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="piecesPacked"
                      onChange={(e) => handleChange(e)}
                      value={formInput.piecesPacked}
                      className="piecesPacked require"
                      readOnly={formInput.pieces.length !== 0}
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Total Meters Packed in This Bale</label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      value={formInput.baleTotal}
                      className="material baleTotal require"
                      name="baleTotal"
                      onChange={(e) => handleChange(e)}
                      readOnly={formInput.pieces.length !== 0}
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Bill Code</label>
                  <Input fullWidth size="Small">
                    <input
                      maxLength={6}
                      max={999999}
                      type="number"
                      value={formInput.billCode}
                      className="billCode"
                      name="billCode"
                      onChange={(e) => {
                        if (e.target.value > 999999) return;
                        handleChange(e);
                      }}
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Remarks</label>
                  <Input fullWidth size="Small">
                    <input
                      maxLength={20}
                      type="text"
                      value={formInput.remarks}
                      className="remarks"
                      name="remarks"
                      onChange={(e) => handleChange(e)}
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="pi-operation-container machine-items">
                  {formInput.pieces.length > 0 && <div className="machine-util-title">Pieces</div>}
                  {formInput.pieces.map((item, key) => {
                    return (
                      <Row key={'item-' + key} className="machine-inputs">
                        <Col breakPoint={{ xs: 12, md: 4 }}>
                          {key == 0 && <label className="div-full-label">Color </label>}
                          <Input fullWidth size="Small">
                            <input
                              type="text"
                              name="color"
                              className={`piecesPacked ${formInput.pieces[key]?.meters !== '' ? 'require' : ''}`}
                              value={item.color}
                              onChange={(e) => itemInputChange(e, key)}
                            />
                          </Input>
                        </Col>
                        <Col breakPoint={{ xs: 12, md: 4 }}>
                          {key == 0 && <label className="div-full-label">Meter </label>}
                          <Input fullWidth size="Small">
                            <input
                              type="text"
                              name="meters"
                              className={`piecesPacked  ${formInput.pieces[key]?.color !== '' ? 'require' : ''}`}
                              value={item.meters}
                              onChange={(e) => itemInputChange(e, key, true)}
                            />
                          </Input>
                        </Col>
                        <div onClick={() => removeItems(key)}>
                          <label className="div-full"></label>
                          <label className={'delBtn piOperationDelete div-full ' + (key > 0 ? 'notfirst' : '')}>
                            <span>-</span>
                          </label>
                        </div>
                      </Row>
                    );
                  })}
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="PiAddBtnContainer">
                  <Button onClick={() => AddItem()} className="addPibtn">
                    Add Piece <EvaIcon name="plus" className="plusIcon" />
                  </Button>
                </Col>
                <Col breakPoint={{ xs: 12, md: 6 }}></Col>

                <Col breakPoint={{ xs: 12, md: 12 }} className="mt20">
                  <Button
                    disabled={formOK || !isSave}
                    onClick={postApi}
                    status="Success"
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    status="danger"
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                    onClick={() => {
                      clearFormData();
                      baleNumSetter();
                    }}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Packing Slip Entry</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default PackingSlipEntry;
