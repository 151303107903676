// import React from 'react';
// import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
// import TahomaBold from '../../fonts/Tahoma-Bold.ttf'; // Adjust the path to your font file

// export default function PdfComponent({ millName, SORT, SHADE, ROLL_NO, bNO, lMtrs, netWT, grossWt, date }: any) {
//   Font.register({
//     family: 'TahomaBold',
//     src: TahomaBold,
//   });
//   const mmToPoints = (mm) => mm * 2.83465;
//   const styles = StyleSheet.create({
//     document: {
//       color: 'black',
//       fontFamily: 'TahomaBold',
//     },
//     page: {
//       padding: 10,
//     },
//     size14: {
//       fontSize: 13,
//     },
//     size10: {
//       fontSize: 11,
//     },
//     size16: {
//       fontSize: 14,
//     },
//     size12: {
//       fontSize: 12,
//     },
//     size18: {
//       fontSize: 18,
//     },
//     row: {
//       display: 'flex',
//       flexDirection: 'row',
//       justifyContent: 'space-between',
//       width: '100%',
//       marginBottom: 5,
//     },
//     rowCol: {
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'flex-start',
//       width: '100%',
//       marginBottom: 5,
//     },
//     column: {
//       flexBasis: '50%',
//       display: 'flex',
//       justifyContent: 'flex-start',
//     },
//     valueColumn: {
//       flexBasis: '50%',
//       display: 'flex',
//       justifyContent: 'flex-end',
//     },
//     alignCenter: {
//       textAlign: 'center',
//     },
//   });

//   return (
//     <Document style={styles.document}>
//       <Page size={{ width: mmToPoints(105), height: mmToPoints(75) }} style={styles.page} orientation="portrait">
//         <View
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             border: '1pt solid black',
//             height: '100%',
//             width: '100%',
//             padding: 5,
//           }}
//         >
//           <View style={styles.row}>
//             <View style={styles.column}>
//               <Text style={styles.size14}>MILL NAME</Text>
//             </View>
//             <View style={styles.valueColumn}>
//               <Text style={styles.size16}>: {millName}</Text>
//             </View>
//           </View>
//           <View style={styles.rowCol}>
//             <View>
//               <Text style={styles.size10}>SORT NO:</Text>
//             </View>
//             <View>
//               <Text style={styles.size14}>{SORT}</Text>
//             </View>
//           </View>
//           <View style={styles.row}>
//             <View style={styles.column}>
//               <Text style={styles.size14}>SHADE</Text>
//             </View>
//             <View style={styles.valueColumn}>
//               <Text style={styles.size14}>: {SHADE}</Text>
//             </View>
//           </View>
//           <View style={styles.row}>
//             <View style={styles.column}>
//               <Text style={styles.size14}>ROLL/B.NO</Text>
//             </View>
//             <View style={styles.valueColumn}>
//               <Text style={styles.size18}>
//                 : {ROLL_NO}/{bNO}
//               </Text>
//             </View>
//           </View>
//           <View style={styles.row}>
//             <View style={styles.column}>
//               <Text style={styles.size14}>L.MTRS</Text>
//             </View>
//             <View style={styles.valueColumn}>
//               <Text style={styles.size16}>: {lMtrs}</Text>
//             </View>
//           </View>
//           <View style={styles.row}>
//             <View style={styles.column}>
//               <Text style={styles.size14}>Net WT</Text>
//             </View>
//             <View style={styles.valueColumn}>
//               <Text style={styles.size16}>: {netWT} Kgs</Text>
//             </View>
//           </View>
//           <View style={styles.row}>
//             <View style={styles.column}>
//               <Text style={styles.size14}>Gross WT</Text>
//             </View>
//             <View style={styles.valueColumn}>
//               <Text style={styles.size16}>: {grossWt} Kgs</Text>
//             </View>
//           </View>
//           <View style={styles.row}>
//             <View style={styles.column}>
//               <Text style={styles.size14}>DATE</Text>
//             </View>
//             <View style={styles.valueColumn}>
//               <Text style={styles.size14}>: {date}</Text>
//             </View>
//           </View>
//         </View>
//       </Page>
//     </Document>
//   );
// }
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import TahomaBold from '../../fonts/Tahoma-Bold.ttf'; // Adjust the path to your font file

export default function PdfComponent({ millName, SORT, SHADE, ROLL_NO, bNO, lMtrs, netWT, grossWt, date }: any) {
  Font.register({
    family: 'TahomaBold',
    src: TahomaBold,
  });
  const mmToPoints = (mm) => mm * 2.83465;
  const styles = StyleSheet.create({
    document: {
      color: 'black',
      fontFamily: 'TahomaBold',
    },
    page: {
      padding: 10,
    },
    size14: {
      fontSize: 13,
    },
    size10: {
      fontSize: 11,
    },
    size16: {
      fontSize: 14,
    },
    size12: {
      fontSize: 12,
    },
    size18: {
      fontSize: 18,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: 5,
    },
    rowCol: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: 5,
    },
    column: {
      flexBasis: '45%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    valueColumn: {
      flexBasis: '95%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    alignCenter: {
      textAlign: 'center',
    },
  });

  return (
    <Document style={styles.document}>
      <Page size={{ width: mmToPoints(105), height: mmToPoints(75) }} style={styles.page} orientation="portrait">
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1pt solid black',
            height: '100%',
            width: '100%',
            padding: 5,
          }}
        >
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.size14}>MILL NAME</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.size16}>: {millName}</Text>
            </View>
          </View>
           <View style={styles.rowCol}>
             <View>
               <Text style={styles.size10}>SORT NO:</Text>
             </View>
             <View>
               <Text style={styles.size14}>{SORT}</Text>
             </View>
           </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.size14}>SHADE</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.size14}>: {SHADE}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.size14}>ROLL/B.NO</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.size18}>
                : {ROLL_NO}/{bNO}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.size14}>L.MTRS</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.size16}>: {lMtrs}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.size14}>Net WT</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.size16}>: {netWT} Kgs</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.size14}>Gross WT</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.size16}>: {grossWt} Kgs</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.size14}>DATE</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.size14}>: {date}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}


